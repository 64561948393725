<template>
    <div>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <!-- <v-img src="./../../assets/images/ele.png" width="100%" contain>
            <template v-slot:placeholder>
              <ImageLoader />
            </template>
          </v-img> -->
          <!-- <v-card outlined> -->
          <v-layout wrap justify-center>
            <v-flex xs12>
              <div id="map"></div>
            </v-flex>
          </v-layout>
          <!-- </v-card> -->
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center py-4 py-md-12 pl-2 pr-2>
        <v-flex xs12 lg9 xl8>
          <v-layout wrap justify-space-between>
            <v-flex xs12 md12 pr-md-8>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span class="itemHeading"> GET TO MUDUMALAI</span>
                </v-flex>
                <v-flex xs12>
                  <v-progress-linear value="100" color="#FF7C03"></v-progress-linear>
                </v-flex>
                <v-flex xs12 pt-4 text-justify>
                  <span class="itemCaption">
                    The Sanctuary straddles the Ooty – Mysore inter-state Highway roughly midway between the two cities
                    (Theppakadu being 91 km, from Mysore and 67 km, from Ooty) and could be approached with equal facility
                    from either direction. From Theppakadu there are two routes to Ooty. One a short cut over the steep and
                    narrow Sigur Ghat Road, which has a gradient of 1 in 10 in places and has to negotiated with care. Over
                    this route Theppakadu is only 36 km from Ooty as against 67 km, over the Highway Via. Gudalur. The
                    Sanctuary is well served by buses, particularly from Ooty. Long distance inter-state buses from
                    Bangalore and Mysore pass through Theppakadu, Unless reservation is made in advance, it is difficult to
                    board these buses at Theppakadu. <br> <br>
  
                    The nearest railhead to the south is Ooty and the most convenient railhead to the north is Mysore. <br><br>
  
                    During the summer season, special buses are run to Mudumalai and back from Ooty. Tourist cars and vans
                    are available on hire in the neighbouring towns. Bangalore and Coimbatore airports are 240 km, and 170
                    km, from Theppakadu respectively.
  
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-space-between pt-10>
            <v-flex>
              <v-layout wrap justify-center>
                <v-flex xs12 text-center>
                  <v-icon x-large color="black">mdi-gmail</v-icon>
                </v-flex>
                <v-flex xs12 text-center pt-3>
                  <span class="contactHeading">mtronlinebooking@gmail.com</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout wrap justify-center>
                <v-flex xs12 text-center>
                  <v-icon x-large color="black">mdi-phone</v-icon>
                </v-flex>
                <v-flex xs12 text-center pt-3>
                  <span class="contactHeading">For Room Booking: <br>
                    +91 948-777-5971 <br> <br>
  
                    For Safari Enquiry: <br>
                    +91 830-091-8235</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout wrap>
                <v-flex xs12 text-center>
                  <v-icon x-large color="black">mdi-map-marker</v-icon>
                </v-flex>
                <v-flex xs12 text-center pt-3>
                  <span class="contactHeading">Reception Office, Mount Stuart Hill, Ooty</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBpukNl1L1qGqEwad87A1-MkZj8H_bKDZM&libraries=visualization,drawing"></script>
  <script>
  var marker;
  export default {
    props: ["storage"],
    data() {
      return {
        map: "",
        mapData: {},
      };
    },
    mounted() {
      this.mapData = this.storage;
      this.initMap();
    },
    methods: {
      initMap() {
        var vm = this;
        var mapCanvas = document.getElementById("map");
        var mapOptions = {
          center: new google.maps.LatLng(
            11.5623,
            76.5345
          ),
          zoom: 10,
  
          mapTypeId: google.maps.MapTypeId.TERRAIN,
        };
        vm.map = new google.maps.Map(mapCanvas, mapOptions);
        var lat = 11.5623;
        var lon = 76.5345;
        var latlngset = new google.maps.LatLng(lat, lon);
        marker = new google.maps.Marker({
          map: vm.map,
          position: latlngset,
          // icon: icon,
        });
      },
    },
  };
  </script>
  <style scoped>
  .contactHeading {
    font-family: poppinsbold;
    font-size: 18px;
  }
  .itemCaption {
  font-family: poppinsmedium;
  font-size: 18px;
  color: #1a1919;
}
.itemHeading {
  font-family: poppinsbold;
  font-size: 25px;
  color: #000000;
  letter-spacing: 1px;
}
  #map {
    height: 400px;
  }
  </style>